@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic&display=swap');
@import '~@fortawesome/fontawesome-pro/css/solid.min.css';

/* OVERWRITTEN AND EXTRA VARIABLES */
$primary: #01BABF !default;
$secondary: #2acbb7 !default;
$gray-100: #fafafa !default;

$font-family-sans-serif: "Noto Sans", sans-serif !default;
$font-family-sans-serif-arabic: 'Noto Sans Arabic', sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

$font-weight-ultra-light: 100 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;

$body-color: #646A89 !default;

$border-radius-lg: 0.875rem !default;

$breadcrumb-divider: quote("-") !default;

@import "~bootstrap/scss/bootstrap";

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: $font-family-sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5;
  color: $body-color !important;
  text-align: start;
  background-color: #fff;
  letter-spacing: 0;
}

.ur, .ar {
  font-family: $font-family-sans-serif-arabic !important;
  font-size: 1rem !important;
  line-height: 1.7;
}
/* SITE SECTION */

.site-section {
  padding: 4.5em 0;

  &-top {
    padding: 4.5em 0 0;
  }

  &-bottom {
    padding: 0 0 4.5em;
  }

  &.site-section-sm {
    padding: 3.5em 0;
  }

  &-coming-soon {
    padding: 10em 0;
  }
}

@media (min-width: 768px) {
  .site-section {
    padding: 7.5em 0;

    &-top {
      padding: 7.5em 0 0;
    }

    &-bottom {
      padding: 0 0 7.5em;
    }

  }
}

.site-section-heading {
  position: relative;
  font-size: 2.5rem;

  &:after {
    content: "";
    left: 0;
    bottom: 0;
    position: absolute;
    width: 100px;
    height: 1px;
    background: $primary;
  }

  &.text-center:after {
    content: "";
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
    bottom: 0;
    position: absolute;
    width: 100px;
    height: 1px;
    background: $primary;
  }
}

@media (min-width: 768px) {
  .site-section-heading {
    font-size: 4rem;
  }
}

/* BLOCKS */

.site-blocks-cover-flipped {
  background-size: cover;
  background: url("assets/images/hero-banner-flipped.png") no-repeat center left;
  min-height: 340px;
  height: calc(36vh);
}

.site-blocks-cover {
  background-size: cover;
  background: url("assets/images/hero-banner.png") no-repeat center right;
  min-height: 340px;
  height: calc(36vh);
  //margin-top: -118px;
}

.site-blocks-cover, .site-blocks-cover-flipped {

  &.overlay {
    position: relative;

    &:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
    }
  }

  .row {
    min-height: 340px;
    height: calc(36vh);
  }

  &.inner-page-cover {
    min-height: 340px;
    height: calc(36vh);

    .row {
      min-height: 340px;
      height: calc(36vh);
    }
  }

  h1 {
    font-size: 4rem;
    font-weight: 900;
    color: $primary;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }

  a {
    text-decoration: none;
  }

  .lead {
    font-size: 1.25rem;
    font-weight: 400;
  }

  .btn {
    border: 2px solid transparent;

    &:hover {
      color: #fff !important;
      background: none;
      border: 2px solid #fff;
    }
  }

  .intro-text {
    font-size: 16px;
    line-height: 1.5;
  }
}

@media (max-width: 991.98px) {
  .site-blocks-cover h1 {
    font-size: 2rem !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .p-5 {
    padding: 1.5rem !important;
  }
}

@media (max-width: 991.98px) {
  .site-blocks-cover .display-1 {
    font-size: 3rem;
  }
}

.title-section {
  &_title {
    font-size: 1.6rem;
    color: $primary;
    line-height: 1rem;
  }
  &_msg {
    font-size: 2.8rem;
    color: $primary;
    text-transform: capitalize;
    font-weight: 600;
  }
  &_desc {
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.7rem;
    color: $body-color;
  }
}

.text-start, .text-sm-start, .text-md-start, .text-lg-start, .text-xl-start {
  text-align: start !important;
}

.text-end, .text-sm-end, .text-md-end, .text-lg-end, .text-xl-end {
  text-align: end !important;
}

.rounded-xl {
  border-radius: 2rem !important;
}

.hero-home-vh-100 {
  height: calc(100vh - 118px);
  //margin-bottom: 3.5rem;
  @media (min-width: 768px) {
    height: calc(100vh - 200px);
  }
}

.dynamic-title {
  text-align: start;
  color: $primary;
  line-height: 3em;

  @media (max-width: 960px) {
    margin-top: 2rem;
  }

  &-bold {
    font-weight: bolder;
    font-size: 4rem;
  }

  &-regular {
    font-weight: normal;
    font-size: 3.5rem;
  }
}

.modal-button {
  padding: 1.5rem 0;
  border-radius: 40px 40px 4px 40px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  &:hover {
    color: white;
    //border-radius: 40px 40px 4px 40px;
    background-color: #01BABF;
  }

}